<template>
  <div style="display:inline-block">
    <v-tooltip bottom color="primary">
      <template v-slot:activator="{on}">
        <span v-on="on">
          <slot></slot>
        </span>
      </template>
      <span>{{ label }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'TheToolTip',
  data: () => ({}),
  props: {
    label: String,
  },
}
</script>

<style></style>

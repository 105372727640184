<template>
  <v-container fluid>
    <TheTitle :text="title" :icon="pageIcon">
      <v-row justify="center">
        <v-col md="8" sm="10" cols="10">
          <v-text-field
            placeholder="Procurar"
            dense
            solo
            background-color="#FFFFFF"
            hide-details
            v-model="search"
          ></v-text-field>
        </v-col>
        <v-col md="4" sm="4" cols="4">
          <v-btn block color="amber darken-4" dark @click="dialogSave()">
            <v-icon small class="mr-2">mdi-plus</v-icon> Adicionar
          </v-btn>
        </v-col>
      </v-row>
    </TheTitle>

    <v-card class="pa-5 full-height">
      <v-data-table :headers="header" :items="objects" dense locale="pt-BR" :search="search">
        <template v-slot:item.idPosition="{ item }">
          {{ getPositionName(item.idPosition) }}
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <TheToolTip label="Editar">
            <v-btn icon small tile>
              <v-icon @click="dialogUpdate(item)">mdi-pen</v-icon>
            </v-btn>
          </TheToolTip>
          <TheToolTip label="Remover">
            <v-btn icon small tile>
              <v-icon @click="remove(item.id)">mdi-minus</v-icon>
            </v-btn>
          </TheToolTip>
        </template>
      </v-data-table>
    </v-card>

    <TheDialog v-model="dialog" :title="title" :icon="pageIcon" width="1200">
      <template>
        <v-form v-model="valid" ref="form1">
          <v-tabs v-model="tabs" show-arrows>
            <v-tab>Dados Pessoais</v-tab>
            <v-tab>contato</v-tab>
            <v-tab>Endereço</v-tab>
            <v-tab>Observação</v-tab>
          </v-tabs>
          <v-divider class="my-5" />

          <v-tabs-items v-model="tabs">
            <v-tab-item>
              <v-row>
                <v-col md="2" sm="3" cols="3">
                  <b> Código </b>
                  <v-text-field
                    hide-dtails
                    v-model="object.code"
                    outlined
                    tile
                    dense
                    class="my-0"
                    :rules="[spaceRule]"
                  ></v-text-field>
                </v-col>
                <v-col md="8" sm="9" cols="9">
                  <b> Nome </b>
                  <v-text-field
                    :rules="[requiredRule, spaceRule]"
                    v-model="object.name"
                    hide-dtails
                    outlined
                    tile
                    dense
                    class="my-0"
                  ></v-text-field>
                </v-col>
                <v-col md="2" sm="12" cols="12">
                  <b> Cargo </b>
                  <v-select
                    v-model="object.idPosition"
                    :items="positions"
                    hide-dtails
                    item-text="name"
                    item-value="id"
                    outlined
                    tile
                    dense
                    hide-details
                    class="my-0"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="4" sm="6" cols="6">
                  <b> Data Nascimento </b>
                  <v-text-field
                    v-model="object.birthday"
                    hide-dtails
                    v-mask="'##/##/####'"
                    outlined
                    tile
                    dense
                    class="my-0"
                  >
                  </v-text-field>
                </v-col>
                <v-col md="4" sm="6" cols="6">
                  <b> CPF </b>
                  <v-text-field
                    v-model="object.cpf"
                    hide-dtails
                    v-mask="'###.###.###-##'"
                    outlined
                    tile
                    dense
                    class="my-0"
                  ></v-text-field>
                </v-col>
                <v-col md="4" sm="6" cols="6">
                  <b> RG </b>
                  <v-text-field
                    v-model="object.rg"
                    hide-dtails
                    v-mask="'##.###.###-#'"
                    outlined
                    tile
                    dense
                    class="my-0"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col md="4" sm="6" cols="6">
                  <b> CNH </b>
                  <v-text-field
                    v-model="object.cnh"
                    v-mask="'###########'"
                    hide-dtails
                    outlined
                    tile
                    dense
                    class="my-0"
                  ></v-text-field>
                </v-col>
                <v-col md="4" sm="6" cols="6">
                  <b> Tipo CNH </b>
                  <v-text-field
                    v-model="object.cnhType"
                    v-mask="'SSSS'"
                    hide-dtails
                    outlined
                    tile
                    dense
                    class="my-0"
                  ></v-text-field>
                </v-col>
                <v-col md="4" sm="6" cols="6">
                  <b> Data Vencimento </b>
                  <v-text-field
                    v-model="object.cnhDate"
                    v-mask="'##/##/####'"
                    hide-dtails
                    outlined
                    tile
                    dense
                    class="my-0"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item>
              <v-row>
                <v-col md="3" sm="6" cols="6">
                  <b> Telefone </b>
                  <v-text-field
                    v-model="object.phone"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                    outlined
                    tile
                    dense
                    class="my-2"
                    :rules="[validarTelefone]"
                  ></v-text-field>
                </v-col>
                <v-col md="3" sm="6" cols="6">
                  <b> Celular </b>
                  <v-text-field
                    v-model="object.cellphone"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                    outlined
                    tile
                    dense
                    class="my-2"
                    :rules="[validarTelefone]"
                  ></v-text-field>
                </v-col>
                <v-col md="6" sm="8" cols="8">
                  <b> Email </b>
                  <v-text-field
                    v-model="object.email"
                    outlined
                    tile
                    dense
                    class="my-2"
                    :rules="[validarEmail]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item>
              <v-row>
                <v-col md="2" sm="4" cols="4">
                  <b> Cep </b>
                  <v-text-field
                    v-model="object.addressZipcode"
                    @keyup="getCep()"
                    v-mask="'#####-###'"
                    hide-dtails
                    outlined
                    tile
                    dense
                    class="my-0"
                    :rules="[validarCEP]"
                  ></v-text-field>
                </v-col>
                <v-col md="6" sm="8" cols="8">
                  <b> Endereço </b>
                  <v-text-field
                    v-model="object.addressStreet"
                    outlined
                    hide-dtails
                    tile
                    dense
                    class="my-0"
                    :rules="[spaceRule]"
                  ></v-text-field>
                </v-col>
                <v-col md="2" sm="4" cols="4">
                  <b> Número </b>
                  <v-text-field
                    v-model="object.addressNumber"
                    outlined
                    hide-dtails
                    tile
                    dense
                    class="my-0"
                    :rules="[spaceRule]"
                  ></v-text-field>
                </v-col>
                <v-col md="2" sm="8" cols="8">
                  <b> Bairro </b>
                  <v-text-field
                    v-model="object.addressNeighborhood"
                    outlined
                    hide-dtails
                    tile
                    dense
                    class="my-0"
                    :rules="[spaceRule]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="4" sm="12" cols="12">
                  <b> Cidade </b>
                  <v-text-field
                    v-model="object.addressCity"
                    outlined
                    hide-dtails
                    tile
                    dense
                    class="my-0"
                    :rules="[spaceRule]"
                  ></v-text-field>
                </v-col>
                <v-col md="4" sm="6" cols="6">
                  <b> Estado </b>
                  <v-text-field
                    v-model="object.addressState"
                    outlined
                    hide-dtails
                    tile
                    dense
                    class="my-0"
                    :rules="[spaceRule]"
                  ></v-text-field>
                </v-col>
                <v-col md="4" sm="6" cols="6">
                  <b> País </b>
                  <v-text-field
                    v-model="object.addressCountry"
                    outlined
                    hide-dtails
                    tile
                    dense
                    class="my-0"
                    :rules="[spaceRule]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item>
              <v-col cols="12">
                <b> Observações </b>
                <v-textarea outlined hide-dtails v-model="object.observation"></v-textarea>
              </v-col>
              <v-col md="2" sm="4" cols="4">
                <b> Status </b>
                <v-switch v-model="object.type" inset :label="object.type ? 'Ativo' : 'Inativo'"></v-switch>
              </v-col>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </template>
      <template v-slot:actions="">
        <v-btn small depressed color="primary" class="mt-2" dark @click="resolveForm()">
          <v-icon small class="mr-2">mdi-plus</v-icon>
          <span>Salvar</span>
        </v-btn>
        <v-spacer></v-spacer>
        <span class="ml-5 caption"><sup>*</sup>Campos obrigatórios</span>
      </template>
    </TheDialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import { baseApiUrl, showError } from '@/global'
import { mapState } from 'vuex'
import { mask } from 'vue-the-mask'
import TheTitle from '@/components/TheTitle'
import TheDialog from '@/components/TheDialog'
import TheToolTip from '@/components/TheToolTip'

export default {
  name: 'Pessoas',
  directives: { mask },
  components: {
    TheTitle,
    TheDialog,
    TheToolTip,
  },
  computed: {
    ...mapState(['user']),

    getPositionName() {
      return function(idPosition) {
        const position = this.positions.find((el) => el.id === idPosition);
        if (position) {
          return position.name;
        } else {
          return "";
        }
      };
    },
  },
  data: () => ({

    baseRoute: 'people',
    title: 'Gerência de Pessoas',
    pageIcon: 'mdi-account-reactivate',

    object: {},
    // address: {},
    objects: [],
    positions: [],

    dialog: false,
    search: null,
    valid: false,
    error: false,
    edit: false,
    loading: false,
    tabs: null,

    header: [
      { text: 'Nome', value: 'name' },
      { text: 'Cargos', value: 'idPosition' },
      { text: 'Ações', value: 'action', width: '10%' },
    ],
    
  }),

  methods: {
    loadData() {
      this.$store.dispatch('setLoadingInfo', true)
      const url = `${baseApiUrl}/${this.baseRoute}`
      axios
        .get(url)
        .then((res) => {
          this.$store.dispatch('setLoadingInfo', false)
          this.objects = res.data
        })
        .catch(showError)
    },

    loadPosition() {
      const url = `${baseApiUrl}/position`
      axios
        .get(url)
        .then((res) => {
          this.positions = res.data
        })
        .catch(showError)
    },

    getCep() {
      if (this.object.addressZipcode.length < 8) {
        return
      }
      const cep = this.object.addressZipcode.replace('-', '')
      const url = `${baseApiUrl}/cep/${cep}`
      this.loading = true

      axios
        .get(url)
        .then((res) => {
          this.dialog = false
          const data = res.data
          this.object.addressStreet = data.logradouro
          this.object.addressNeighborhood = data.bairro
          this.object.addressCity = data.localidade
          this.object.addressState = data.uf
          this.object.addressCountry = 'Brasil'
          this.loading = false
          this.dialog = true
        })
        .catch(showError)
    },

    dialogSave() {
      this.object = {}
      this.edit = false
      this.dialog = true
    },

    dialogUpdate(item) {
      this.object = item
      this.object.type == 1 ? (this.object.type = true) : (this.object.type = false)
      this.edit = true
      this.dialog = true
    },

    resolveForm() {
      if (!this.valid) {
        this.$store.dispatch('setErrorInfo', {
          open: true,
          text: 'Os campos não foram preenchidos corretamente, você pode verificar?',
          button: 'Ok!',
        })
        return
      }
      if (this.edit) {
        this.update()
      } else {
        this.save()
      }
    },

    save() {
      this.error = false
      const url = `${baseApiUrl}/${this.baseRoute}`
      const objects = this.object
      axios
        .post(url, objects)
        .then(() => this.loadData())
        .catch(showError)
        .finally(() => {
          this.dialog = false
        })
    },

    update() {
      this.error = false
      const id = this.object.id
      const url = `${baseApiUrl}/${this.baseRoute}/${id}`
      const objects = this.object
      delete objects.id

      delete objects.createdAt
      delete objects.updatedAt
      delete objects.deletedAt
      axios
        .put(url, objects)
        .then(() => this.loadData())
        .catch(showError)
        .finally(() => {
          this.dialog = false
        })
    },

    remove(id) {
      if (confirm('Remove?')) {
        const url = `${baseApiUrl}/${this.baseRoute}/${id}`
        axios
          .delete(url)
          .then(() => this.loadData())
          .catch(showError)
      }
    },

  },
  async mounted() {
    await this.loadData()
    this.loadPosition()
  },
}
</script>

<style>
  .full-height {
    height: 80vh;
  }
</style>
